<template>
  <h2>AI Generated Predictions</h2>
  <br>
  <div class="tiles">
    <div v-if="sport !== 'Basketball'" class="category tile full-width">
      <h2>Result</h2>
      <div class="button-wrapper">
        <button class="probability-button">
          <div v-for="result in ['Home Win', 'Draw', 'Away Win']" :key="result"
               class="button-label"
               :style="{width: formatProbability(gameDetails.classes[result]?.probability),
                        backgroundColor: highestProbability('Home Win', 'Draw', 'Away Win') === result ? '#d4edda' : '#f8f9fa'}">
            <span>{{ result }}</span>
            <span>{{ formatProbability(gameDetails.classes[result]?.probability) }}</span>
            <span class="odds">{{ gameDetails.classes[result]?.['decimal odds'].toFixed(2) }}</span>
          </div>
        </button>
      </div>
    </div>
    <div v-if="sport === 'Basketball'" class="category tile full-width">
      <h2>Result</h2>
      <div class="button-wrapper">
        <button class="probability-button">
          <div v-for="result in ['Home Win', 'Away Win']" :key="result"
               class="button-label"
               :style="{width: formatProbability(gameDetails.classes[result]?.probability),
                        backgroundColor: highestProbability('Home Win', 'Away Win') === result ? '#d4edda' : '#f8f9fa'}">
            <span>{{ result }}</span>
            <span>{{ formatProbability(gameDetails.classes[result]?.probability) }}</span>
            <span class="odds">{{ gameDetails.classes[result]?.['decimal odds'].toFixed(2) }}</span>
          </div>
        </button>
      </div>
    </div>
    <div v-for="category in categories" :key="category.name" class="category tile">
      <h2>{{ category.name }}</h2>
      <div class="button-wrapper">
        <button class="probability-button">
          <div v-for="type in category.types" :key="type.type"
               class="button-label"
               :style="{width: formatProbability(gameDetails.classes[type.type]?.probability),
                        backgroundColor: highestProbability(...category.types.map(t => t.type)) === type.type ? '#d4edda' : '#f8f9fa'}">
            <span>{{ type.type }}</span>
            <span>{{ formatProbability(gameDetails.classes[type.type]?.probability) }}</span>
            <span class="odds">{{ gameDetails.classes[type.type]?.['decimal odds'].toFixed(2) }}</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

  
  <script>
  export default {
    props: {
      gameDetails: {
        type: Object,
        required: true
      },
      sport: {
        type: String,
        required: true
      }
    },
    methods: {
      highestProbability(...types) {
        let highest = types[0];
        types.forEach(type => {
          if (this.gameDetails && this.gameDetails.classes[type] && this.gameDetails.classes[highest] &&
              this.gameDetails.classes[type].probability > this.gameDetails.classes[highest].probability) {
            highest = type;
          }
        });
        return highest;
      },
      formatProbability(probability) {
        return probability ? `${(probability * 100).toFixed(0)}%` : '';
      }
    },
    computed: {
      categories() {
        if (this.sport === 'Football') {
          return [
            { name: 'BTTS', types: [{type: 'BTTS No'}, {type: 'BTTS Yes'}] },
            { name: '0.5 Goals', types: [{type: 'Over 0.5'}, {type: 'Under 0.5'}] },
            { name: '1.5 Goals', types: [{type: 'Over 1.5'}, {type: 'Under 1.5'}] },
            { name: '2.5 Goals', types: [{type: 'Over 2.5'}, {type: 'Under 2.5'}] },
            { name: '3.5 Goals', types: [{type: 'Over 3.5'}, {type: 'Under 3.5'}] },
            { name: '4.5 Goals', types: [{type: 'Over 4.5'}, {type: 'Under 4.5'}] }
          ];
        }
        return [];
      }
    }
  };
  </script>
  
  <style scoped>
  .tiles {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Sets two equal columns for desktop */
    gap: 10px;
  }
  
  .tile {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .full-width {
    grid-column: 1 / -1; /* This makes the tile span all available columns */
  }
  
  .button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .probability-button {
    width: 100%;
    padding: 0;
    border: none;
    display: flex;
    text-align:left;
    overflow: hidden;
    border-radius: 5px;
  }
  
  .button-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 10px;
    flex-basis: 0; /* ensures that each label takes equal space */
    border-right: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .button-label:last-child {
    border-right: none; /* removes the border from the last element */
  }
  
  .odds {
    font-weight: bold;
  }
  
  h2 {
    color: #495057;
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {
    .tiles {
      grid-template-columns: 1fr; /* Stacks tiles into a single column for mobile */
    }
  
    .tile, .full-width {
      grid-column: 1 / -1; /* Makes each tile take full width on mobile */
    }
  
    .button-label {
      flex-direction: column; /* Stacks the label elements vertically */
    }
  
    .button-label span:first-child, .button-label span:nth-child(2), .button-label .odds {
      font-size: 8px; /* Adjusts font sizes for mobile */
      font-weight: bold;
    }
  }
  </style>
  