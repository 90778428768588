<template>
    <div class="editor-comments-container">
      <div v-if="comments.length > 0" class="comment-display">
        <div class="comment-content">
          <div class="comment-display-header">
            <img :src="selectedComment.editor_avatar" alt="Editor Avatar" class="avatar">
            <h3>{{ selectedComment.editor_name }}</h3>
          </div>
          <p>{{ selectedComment.comment }}</p>
        </div>
        <div class="navigation-buttons">
          <div class="left-button">
            <button v-if="commentIndex > 0" @click="navigateComment(-1)" class="nav-button">⟵</button>
          </div>
          <div class="right-button">
            <button v-if="commentIndex < comments.length - 1" @click="navigateComment(1)" class="nav-button">⟶</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      gameId: {
        type: String,
        required: true
      },
      comments: Array
    },
    data() {
      return {
        commentIndex: 0
      };
    },
    computed: {
      selectedComment() {
        return this.comments[this.commentIndex];
      }
    },
    watch: {
      gameId() {
        this.commentIndex = 0; // Reset to the first comment when gameId changes
      },
      comments() {
        this.commentIndex = 0; // Reset to the first comment when comments are updated
      }
    },
    methods: {
      navigateComment(direction) {
        const newIndex = this.commentIndex + direction;
        if (newIndex >= 0 && newIndex < this.comments.length) {
          this.commentIndex = newIndex;
        }
      }
    }
  };
  </script>
  
  <style scoped>
    .editor-comments-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    .comment-display {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f0f0f0;
      border-radius: 8px;
      padding: 20px;
      width: 100%;
    }
  
    .comment-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .navigation-buttons {
      display: flex;
      width: 100%;
      justify-content: space-between; /* Ensures that buttons are on either side */
      padding-top: 10px;
    }
  
    .left-button, .right-button {
      flex: 1; /* Equal width to both sides */
    }
  
    .nav-button {
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      margin-top: -20px;
    }
  
    .comment-display-header {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  
    h3 {
      margin: 0;
    }
  
    p {
      margin-top: 10px;
      text-align: center;
      width: 80%; /* Limit width for better readability */
    }
  </style>
  