<template>
    <div class="team-stats">
      <h2>AI Generated Analysis</h2>
      <div class="stat-bar" v-for="(stat, index) in stats" :key="index">
        <div class="bar">
          <div class="bar-section" :style="barStyle(stat.ft)">
          </div>
          <div class="bar-section" :style="barStyle(stat.st, true)">
          </div>
          <div class="stat-title">{{ stat.title }}</div> <!-- Moved inside bar for centering -->
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "TeamStatistics",
  props: {
    gameDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
    stats() {
      return [
        {
          title: 'Overall',
          ft: this.ft_overall,
          st: this.st_overall
        },
        {
          title: 'Attack',
          ft: this.ft_attack,
          st: this.st_attack
        },
        {
          title: 'Defense',
          ft: this.ft_defense,
          st: this.st_defense
        },
        {
          title: 'Form',
          ft: this.ft_form,
          st: this.st_form
        }
      ];
    },
    ft_overall() {
      return this.calculatePercentage(this.gameDetails.ft_avg_points, this.gameDetails.st_avg_points);
    },
    ft_attack() {
      return this.calculatePercentage(this.gameDetails.ft_avg_scored, this.gameDetails.st_avg_scored);
    },
    ft_defense() {
      return this.calculatePercentage(this.gameDetails.st_avg_conced, this.gameDetails.ft_avg_conced);
    },
    ft_form() {
      return this.calculatePercentage(this.gameDetails.ft_form, this.gameDetails.st_form);
    },
    st_overall() {
      return this.calculatePercentage(this.gameDetails.st_avg_points, this.gameDetails.ft_avg_points);
    },
    st_attack() {
      return this.calculatePercentage(this.gameDetails.st_avg_scored, this.gameDetails.ft_avg_scored);
    },
    st_defense() {
      return this.calculatePercentage(this.gameDetails.ft_avg_conced, this.gameDetails.st_avg_conced);
    },
    st_form() {
      return this.calculatePercentage(this.gameDetails.st_form, this.gameDetails.ft_form);
    }
  },
  methods: {
    calculatePercentage(first, second) {
      return (first / (first + second)) * 100;
    },
    barStyle(percentage = false) {
      // Determine the background color based on the percentage directly for each stat
      const backgroundColor = percentage > 50 ? '#d4edda' : 'white'; // Soft green if this section is larger
      return {
        width: percentage + '%',
        backgroundColor: backgroundColor,
        borderRadius: '5px'
      };
    }
  }
};
</script>




<style scoped>
.team-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #495057;
}

.stat-bar {
  display: flex;
  align-items: center;
  margin: 1.75vh 0;
}

.bar {
  position: relative;
  display: flex;
  height: 3.5vh;
  width: 65vw; /* Adjust this for mobile below */
  background-color: #ffffff;
  border: 2px solid #c3e6cb;
  border-radius: 5px;
  overflow: hidden;
}

.bar-section {
  height: 100%;
  transition: width 0.3s ease;
}

.stat-title {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: small;
  font-weight: bold;
  line-height: 3.5vh; /* Match the bar height to vertically center the text */
}
.h2{
  color:#495057;
}

</style>
