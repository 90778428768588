<template>
  <div class="welcome-text">
    <div class="welcome-logo">
      <img src="@/assets/balleralgo_logo.png" alt="Welcome Logo" />
    </div>
    <p class="intro-text">
    </p>
  </div>
</template>

<script>
export default {
  name: "WelcomeText",
};
</script>

<style scoped>
/* Add styling for the WelcomeText component */
.welcome-text {
  max-width: 600px; /* Adjust the desired width */
  margin: 0 auto; /* Center horizontally */
  text-align: center;
}

.welcome-logo img {
  max-width: 300px;
}

.welcome-logo h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.intro-text {
  font-size: 25px;
  margin-bottom: 20px;
}

</style>
