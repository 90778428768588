<template>
    <header class="landing-header">
      <div class="logo" @click="navigateHome">
        <img src="@/assets/balleralgo_small_logo.png" alt="BallerAlgo Logo" />
      </div>
      <div class="navigation">
        <router-link to="/football">Football</router-link>
        <router-link to="/basketball">Basketball</router-link>
      </div>
    </header>
</template>

<script>
export default {
  name: "LandingPage",
  methods: {
    navigateHome() {
      this.$router.push('/');
    },
    openPortfolio() {
      window.open('https://erdemkaratash.github.io/portfolio', '_blank');
    }
  }
};
</script>


<style scoped>
.landing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5vh;
}

.logo img {
  max-width: 30px;
  cursor: pointer;
}

.navigation {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.navigation a {
  margin-right: 20px;
  text-decoration: none;
  color: #333;
}
</style>
