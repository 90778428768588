<template>
  <AppHeader/>
  <WelcomeText/>
    <div v-if="document" class="text-display">
      <h1>{{ document.title }}</h1>
      <section v-for="(section, index) in document.sections" :key="index">
        <h2>{{ section.header }}</h2>
        <p>{{ section.content }}</p>
      </section>
    </div>
    <div v-else>
      Loading...
    </div>
    <AppFooter/>
  </template>
  
  <script>
  import AppHeader from "@/components/AppHeader.vue"
  import AppFooter from "@/components/AppFooter.vue";
  import WelcomeText from "@/components/WelcomeText.vue";
  export default {
    components:{
      AppHeader,
      AppFooter,
      WelcomeText
    },
    props: {
      textName: String
    },
    data() {
      return {
        document: null
      };
    },
    created() {
      this.loadDocument();
    },
    methods: {
      async loadDocument() {
        try {
          const doc = await import(`@/assets/${this.textName}.json`);
          this.document = doc.default;
        } catch (error) {
          console.error('Failed to load document:', error);
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .text-display {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #333;
  }
  
  h1 {
    font-size: 24px;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 18px;
    color: #2c3e50;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.6;
    text-align: justify;
    margin-bottom: 15px;
  }
  
  @media (max-width: 600px) {
    .text-display {
      padding: 10px;
      margin: 10px;
      font-size: 14px;
    }
  
    h1 {
      font-size: 20px;
    }
  
    h2 {
      font-size: 16px;
    }
  }
  </style>
  