<template>
    <footer class="landing-footer">
      <div class="footer-links">
        <a href="https://erdemkaratash.github.io/portfolio" target="_blank">About</a>
        <router-link to="/conditions/terms">Terms</router-link>
        <router-link to="/conditions/privacy-policy">Privacy Policy</router-link>
      </div>
    </footer>
</template>

<style scoped>
.landing-footer {
  background-color: white;
  text-align: center;
  margin-top: 1vh;
}

.footer-links a {
  margin: 0 10px;
  text-decoration: none;
  color: #333;
  font-size: small;
}
</style>