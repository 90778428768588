<template>
  <div class="comment-display">
    <div class="comment-container" ref="commentContainer">
      <div v-for="comment in comments" :key="comment._id" class="comment-card">
        <div class="card-header">
          <div class="game-info" @click="navigateToComment(comment.sport, comment.id)"> 
             <img :src="comment.first_team_logo" alt="comment.first_team" />
            <p>vs</p>
            <img :src="comment.second_team_logo" alt="comment.second_team" />
          </div>
        </div>
        <div class="card-body">
          <div class="info-button">
            <div class="odds">{{ formattedOdds(comment.pro_odds) }}</div>
            <div class="category">{{ comment.pro_category }}</div>
            <div class="probability">{{ formattedProbability(comment.pro_prob) }}</div>
          </div>
        </div>
        <div class="card-footer">
          <div class="game-info-text" @click="navigateToComment(comment.sport, comment.id)">  
            <p>{{ comment.first_team }}</p>
            <p>vs</p>
            <p>{{ comment.second_team }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
  export default {
    data() {
      return {
        comments: [],
      };
    },
    async mounted() {
      const apiUrl = `${process.env.VUE_APP_API_URL}/football/fetchFeaturedGames`;
      const response = await fetch(apiUrl);
      this.comments = await response.json();
    },
    methods: {
      handleScroll() {
        const container = this.$refs.commentContainer;
        const indicator = this.$refs.scrollIndicator;
        const containerWidth = container.scrollWidth;
        const visibleWidth = container.clientWidth;
        const scrollPosition = container.scrollLeft;
  
        const indicatorWidth = (visibleWidth / containerWidth) * 100;
        const indicatorLeft = (scrollPosition / containerWidth) * 100;
  
        indicator.style.width = `${indicatorWidth}%`;
        indicator.style.left = `${indicatorLeft}%`;
      },
      navigateToComment(sport, gameId) {
      // Replace with your actual routing logic (e.g., Vue Router)
      this.$router.push(`/${sport}/${gameId}`); // Assuming you're using Vue Router
    },
    formattedOdds(odds) {
      return odds.toFixed(2); // Format as percentage with 2 decimal places
    },
    formattedProbability(probability) {
      return (probability * 100).toFixed(1) + '%'; // Format as percentage with 1 decimal place
    },
    },
    unmounted() {
      window.removeEventListener("resize", this.handleScroll);
    },
  };
  </script>
  
  <style scoped>
  .comment-display {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    background-color: #ffffff;
  }
  
  .comment-container {
    display: flex;
    width: fit-content;
  }
  
  .comment-card {
    margin: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 30vh;
  }

  .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow:visible;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}


.card-body::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
  
  .card-header {
    display: flex;
    height: 4.5vh;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    justify-content: center;
  }

  
  .editor-info {
    flex: 1;
  }
  
  .card-body {
    overflow:scroll;
  }
  
  .card-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8vh;
    border-top: 1px solid #ddd;
  }
  
  .scroll-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #ccc;
    border-radius: 2px;
  }

.game-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; /* This indicates the element is clickable */
  transition: opacity 0.2s ease-in-out; /* Add a subtle hover effect */
}

.game-info:hover {
  opacity: 0.8; /* Reduce opacity slightly on hover */
}

.game-info img {
    width:auto;          /* Adjust the width of the images */
    height: 4.5vh;         /* Maintain aspect ratio */
    object-fit: contain;  /* Ensure the image fits well within the dimensions without distorting */
}

.game-info p {
    margin: 0 20px;       /* Add horizontal margin around the 'vs' text */
    display: flex;        /* Use flex to help center text vertically */
    align-items: center;  /* Align text vertically in the center */
}

.game-info-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; /* This indicates the element is clickable */
  transition: opacity 0.2s ease-in-out; /* Add a subtle hover effect */
}

.game-info-text:hover {
  opacity: 0.8; /* Reduce opacity slightly on hover */
}

.game-info-text p {
    margin: 0 10px;       /* Add horizontal margin around the 'vs' text */
    display: flex;        /* Use flex to help center text vertically */
    align-items: center;  /* Align text vertically in the center */
    font-size: x-small;
}

.info-button {
  height: 55px;
  width: 98%;
  background-color: #d4edda;
  border: 2px solid #c3e6cb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.odds, .probability {
  font-size: 0.65em;
  color: #666;
}
.category {
  font-size: 0.65em;
  font-weight: bold;
  color: #333;
}

  </style>
  