<template>
  <div class="landing-page">
    <AppHeader />
    <br>
    <br>
    <WelcomeText />
    <br>
    <p>AI Generated Predictions for Upcoming Football (Soccer) Games</p>
    <FeaturedDisplay />
    <br>
    <p>AI Generated Predictions for Upcoming Basketball Games</p>
    <FeaturedDisplayBasketball />
    <AppFooter />
  </div>
</template>

<script>
import WelcomeText from "@/components/WelcomeText.vue";
import FeaturedDisplay from "@/components/FeaturedDisplay.vue";
import AppHeader from "@/components/AppHeader.vue"
import AppFooter from "@/components/AppFooter.vue"
import FeaturedDisplayBasketball from "@/components/FeaturedDisplayBasketball.vue";



export default {
  name: "LandingPage",
  components: {
    WelcomeText,
    FeaturedDisplay,
    AppHeader,
    FeaturedDisplayBasketball,
    AppFooter
    
  },
};
</script>

<style scoped>
.landing-page {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.landing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  max-width: 30px;
}

.navigation {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.navigation a {
  margin-right: 20px;
  text-decoration: none;
  color: #333;
}

</style>
