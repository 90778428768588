<template>
  <div class="result-page">
    <div class="left-pane">
      <GameSearch :sport="sport" @game-selected="handleGameSelected" />
    </div>
    <div class="right-pane">
      <GameDetails :selectedGameId="selectedGameId" :sport="sport" />
    </div>
  </div>
</template>


<script>
import GameSearch from './GameSearch.vue';
import GameDetails from './GameDetails.vue';

export default {
  components: {
    GameSearch,
    GameDetails
  },
  props: {
    sport: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedGameId: null
    };
  },
  watch: {
    '$route.params.gameId': {
      immediate: true,
      handler(newGameId) {
        this.selectedGameId = newGameId;
      }
    }
  },
  methods: {
    handleGameSelected(gameId) {
      this.selectedGameId = gameId;
      this.$router.push(`/${this.sport}/${gameId}`);
    }
  }
}
</script>

<style scoped>
.result-page {
  display: flex;
  position: relative; /* Added to position the left pane absolutely within */
  min-height: 100vh; /* Ensures the page is at least as tall as the viewport */
}

.left-pane {
  width: 30%;
  position: absolute; /* Positioned absolutely */
  top: 0;
  bottom: 0; /* Stretches from top to bottom of the parent */
  overflow-y: auto; /* Allows internal scrolling if content overflows */
  box-sizing: border-box; /* Includes padding and border in the height calculation */
}

.right-pane {
  width: 70%;
  margin-left: 30%; /* Offset by the width of the left pane */
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allows right pane to determine the height of the result-page */
}
</style>

