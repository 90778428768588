<template>
  <div class="game-tile" 
       :class="{ 'is-selected': isSelected, 'featured-game': game.value > 0 }" 
       @click="selectGame">
    <div class="game-details">
      <div class="game-name">{{ game.name }}</div>
      <div class="game-league">{{ game.league }}</div>
    </div>
    <div class="game-stats">
      <div class="info-button">
        <div class="odds">{{ formattedOdds }}</div>
        <div class="category">{{ game.pro_category }}</div>
        <div class="probability">{{ formattedProbability }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    game: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedOdds() {
      return this.game.pro_odds.toFixed(2);
    },
    formattedProbability() {
      return `${(this.game.pro_prob * 100).toFixed(1)}%`;
    }
  },
  methods: {
    selectGame() {
      this.$emit('game-selected', this.game);
    }
  }
}
</script>

<style scoped>
@keyframes highlightSweep {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.featured-game {
  position: relative;
  overflow: hidden; /* Ensures the background only shows within the tile */
  animation: highlightSweep 3s linear infinite;
  background-image: linear-gradient(to right, rgba(255, 223, 0, 0.2) 0%, rgba(255, 223, 0, 0.8) 50%, rgba(255, 223, 0, 0.2) 100%);
  background-size: 200% 100%;
}

.game-tile {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.game-tile.is-selected {
  background-color: #e0f0ff;
}
.game-details {
  text-align: left;
}
.game-name, .game-league {
  font-weight: bold;
}
.game-name {
  font-size: 1.2em;
}
.game-league {
  font-size: 0.9em;
  color: #666;
}
.info-button {
  width: 55px;
  height: 55px;
  background-color: #d4edda;
  border: 2px solid #c3e6cb;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.odds, .probability {
  font-size: 0.65em;
  color: #666;
}
.category {
  font-size: 0.65em;
  font-weight: bold;
  color: #333;
}

@media (max-width: 600px) {
  .game-name {
    font-size: 0.65em;
  }
  .game-league {
    font-size: 0.55em;
  }
  .info-button {
    display: none;
  }
}
</style>


