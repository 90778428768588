import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router
import VueGtag from "vue-gtag";



createApp(App)
  .use(router)  // Use the router
  .use(VueGtag, {
    config: { 
      id: "G-H11F2YY0H3",
    },
  }, router) // <----- add your router here
  .mount("#app");