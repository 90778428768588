<template>
    <div class="prediction-previews">
      <!-- Football Predictions -->
      <div v-if="sport === 'Football'" class="button-container">
        <div class="info-button" v-for="prediction in footballPredictions" :key="prediction.name">
          <div class="odds">{{ formatOdds(prediction.decimalOdds) }}</div>
          <div class="category">{{ prediction.name }}</div>
          <div class="probability">{{ formatProbability(prediction.probability) }}</div>
        </div>
      </div>
  
      <!-- Basketball Predictions -->
      <div class="button-container">
      <div v-if="sport === 'Basketball' && basketballHighestPrediction" class="info-button">
        <div class="odds">{{ formatOdds(basketballHighestPrediction.decimalOdds) }}</div>
        <div class="category">{{ basketballHighestPrediction.name }}</div>
        <div class="probability">{{ formatProbability(basketballHighestPrediction.probability) }}</div>
    </div>  
    </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      gameDetails: {
        type: Object,
        required: true
      },
      sport: {
        type: String,
        required: true
      }
    },
    computed: {
      footballPredictions() {
        const results = {
          overUnder: [
            { name: 'Over 2.5', probability: this.gameDetails.classes?.['Over 2.5'].probability, decimalOdds: this.gameDetails.classes?.['Over 2.5']['decimal odds'] },
            { name: 'Under 2.5', probability: this.gameDetails.classes?.['Under 2.5'].probability, decimalOdds: this.gameDetails.classes?.['Under 2.5']['decimal odds'] }
          ],
          result: [
            { name: 'Home Win', probability: this.gameDetails.classes?.['Home Win'].probability, decimalOdds: this.gameDetails.classes?.['Home Win']['decimal odds'] },
            { name: 'Draw', probability: this.gameDetails.classes?.['Draw'].probability, decimalOdds: this.gameDetails.classes?.['Draw']['decimal odds'] },
            { name: 'Away Win', probability: this.gameDetails.classes?.['Away Win'].probability, decimalOdds: this.gameDetails.classes?.['Away Win']['decimal odds'] }
          ],
          btts: [
            { name: 'BTTS Yes', probability: this.gameDetails.classes?.['BTTS Yes'].probability, decimalOdds: this.gameDetails.classes?.['BTTS Yes']['decimal odds'] },
            { name: 'BTTS No', probability: this.gameDetails.classes?.['BTTS No'].probability, decimalOdds: this.gameDetails.classes?.['BTTS No']['decimal odds'] }
          ]
        };
        
        // Find highest probability predictions
        return {
          overUnder: results.overUnder.reduce((a, b) => a.probability > b.probability ? a : b),
          result: results.result.reduce((a, b) => a.probability > b.probability ? a : b),
          btts: results.btts.reduce((a, b) => a.probability > b.probability ? a : b)
        };
      },
      basketballHighestPrediction() {
        const results = [
          { name: 'Home Win', probability: this.gameDetails.classes?.['Home Win'].probability, decimalOdds: this.gameDetails.classes?.['Home Win']['decimal odds'] },
          { name: 'Draw', probability: this.gameDetails.classes?.['Draw'].probability, decimalOdds: this.gameDetails.classes?.['Draw']['decimal odds'] },
          { name: 'Away Win', probability: this.gameDetails.classes?.['Away Win'].probability, decimalOdds: this.gameDetails.classes?.['Away Win']['decimal odds'] }
        ];
        return results.reduce((a, b) => a.probability > b.probability ? a : b);
      }
    },
    methods: {
      formatOdds(decimalOdds) {
        return decimalOdds !== undefined ? decimalOdds.toFixed(2) : 'N/A';
      },
      formatProbability(probability) {
        return probability !== undefined ? `${(probability * 100).toFixed(1)}%` : 'N/A';
      }
    }
  }
  </script>
  
  <style scoped>
  .button-container {
    display: flex;
    flex-direction: row;  /* Ensure it's explicitly set to row */
    justify-content: center;
    flex-wrap: wrap;  /* Allows wrapping if necessary, but can be set to nowrap for testing */
    align-items: center;
    width: 100%;  /* Ensure it takes full width */
    gap: 4%;
  }
  
  .info-button {
  width: 8vh;
  height: 8vh;
  background-color: #d4edda;
  border: 2px solid #c3e6cb;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  
  .odds, .probability {
  font-size: x-small;
  color: #666;
}
.category {
  font-size: 0.65em;
  font-weight: bold;
  font-size: x-small;
  color: #333;
}
  </style>
  
  