import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '../components/LandingPage.vue';
import FutGameSearch from '../components/FutGameSearch.vue';
import BasketballGameSearch from '../components/BasketballGameSearch.vue'
import TextDisplay from '@/components/TextDisplay.vue';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    meta: {
      title: 'BallerAlgo – Explore AI-Driven Sports Predictions',
      metaTags: [
        {
          name: 'description',
          content: 'Discover BallerAlgo, your premier platform for AI-driven sports predictions. Analyze sports events and plan your betting strategies with enhanced insight. Access AI Betting Tips with BallerAlgo'
        },
        {
          property: 'og:title',
          content: 'BallerAlgo – Explore AI-Driven Sports Predictions'
        },
        {
          property: 'og:description',
          content: 'Utilize advanced AI technology for comprehensive sports predictions. Dive into detailed analysis of betting strategies, see helpfull betting tips for Football/Soccer and Basketball and your favorite sports.'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:url',
          content: 'https://www.balleralgo.com'
        },
        {
          property: 'og:image',
          content: 'https://www.balleralgo.com/images/ai-sports-prediction.png'
        },
        {
          property: 'og:site_name',
          content: 'BallerAlgo'
        },
        {
          property: 'og:locale',
          content: 'en_US'
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          name: 'twitter:title',
          content: 'BallerAlgo – Explore AI-Driven Sports Predictions'
        },
        {
          name: 'twitter:description',
          content: 'Your go-to source for AI-powered sports predictions. Gain insights into football odds and plan your bets with confidence. Examine AI Football (Soccer) and Basketball predictions.'
        },
        {
          name: 'twitter:image',
          content: 'https://www.balleralgo.com/images/ai-sports-twitter.png'
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          name: 'author',
          content: 'BallerAlgo, Inc.'
        },
        {
          name: 'keywords',
          content: 'football, soccer, sports, odds, betting, betting tips, prediction, sports predictions, AI, AI sports predictions,soccer,soccer predictions, uefa predictions, premier league predictions, turkish superleague predictions'
        }
      ]
    }
  },
  {
    path: '/football/:gameId?',
    name: 'football',
    component: FutGameSearch,
    props: true,
    meta: {
      title: 'AI Football (Soccer) Predictions – Analyze Match Outcomes',
      metaTags: [
        {
          name: 'description',
          content: 'Access AI-driven predictions for Football (Soccer) matches. Examine potential outcomes and odds to strategize your bets effectively. Get betting tips and predictions from AI.'
        },
        {
          property: 'og:title',
          content: 'AI Football (Soccer) Predictions – Analyze Match Outcomes'
        },
        {
          property: 'og:description',
          content: 'Explore in-depth AI analysis for each football (soccer) game. Understand the odds and make informed decisions with BallerAlgo. Utilize AI for Football (Soccer) Predictions.'
        },
        {
          property: 'og:type',
          content: 'article'
        },
        {
          property: 'og:url',
          content: 'https://www.balleralgo.com/football'
        },
        {
          property: 'og:image',
          content: 'https://www.balleralgo.com/images/football-analysis.png'
        },
        {
          name: 'keywords',
          content: 'football, sports, odds, betting, prediction, sports predictions, AI, AI sports predictions,soccer,soccer predictions, uefa predictions, premier league predictions, turkish superleague predictions'
        }
      ]
    }
  },
  {
    path: '/basketball/:gameId?',
    name: 'basketball',
    component: BasketballGameSearch,
    props: true,
    meta: {
      title: 'AI Basketball Predictions – Analyze Match Outcomes',
      metaTags: [
        {
          name: 'description',
          content: 'Access AI-driven predictions for basketball matches. Examine potential outcomes and odds to strategize your bets effectively. Your source for AI Generated Basketball predictions and betting tips.'
        },
        {
          property: 'og:title',
          content: 'AI Basketball Predictions – Analyze Match Outcomes'
        },
        {
          property: 'og:description',
          content: 'Explore in-depth AI analysis for each basketball game. Understand the odds and make informed decisions with BallerAlgo. Utilize AI for Basketball Predictions.'
        },
        {
          property: 'og:type',
          content: 'article'
        },
        {
          property: 'og:url',
          content: 'https://www.balleralgo.com/basketball'
        },
        {
          property: 'og:image',
          content: 'https://www.balleralgo.com/images/basketball-predictions.png'
        },
        {
          name: 'keywords',
          content: 'basketball, sports, odds, betting, prediction, sports predictions, AI, AI sports predictions,nba,euroleague,basketball predictions,euroleague predictions,nba predictions, turkish basketball league predictions'
        }
      ]
    }
  },
  {
    path: '/conditions/:textName',
    name: 'text-display',
    component: TextDisplay,
    props: true,
    meta: {
      title: 'Terms and Conditions – Understand Your Rights',
      metaTags: [
        {
          name: 'description',
          content: 'Read the terms and conditions at BallerAlgo to know your rights and how to use our AI sports prediction tools responsibly.'
        },
        {
          property: 'og:title',
          content: 'Terms and Conditions – Understand Your Rights'
        },
        {
          property: 'og:description',
          content: 'Familiarize yourself with the legal aspects of using BallerAlgo for making informed sports betting decisions.'
        },
        {
          property: 'og:type',
          content: 'article'
        },
        {
          property: 'og:url',
          content: 'https://www.balleralgo.com/conditions'
        },
        {
          property: 'og:image',
          content: 'https://www.balleralgo.com/images/terms-conditions.png'
        }
      ]
    }
  }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// ... 

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

// ...


export default router;
