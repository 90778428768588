<template>
  <div class="game-search">
    <input type="text" v-model="searchTerm" placeholder="Search games, teams, leagues..." @input="filterGames" />
    <div class="flexible-buttons-container">
      <!-- Sorting Buttons -->
      <button class="action-button" :class="{ 'button-pressed': sortMode === 'featured' }" @click="toggleSortMode('featured')">Featured</button>
      <button class="action-button" :class="{ 'button-pressed': sortMode === 'safe' }" @click="toggleSortMode('safe')">Safe</button>
      <!-- Filtering Buttons -->
      <button
        v-for="date in uniqueDates"
        :key="date"
        class="action-button"
        :class="{ 'button-pressed': selectedDates.includes(date) }"
        @click="toggleDateFilter(date)"
      >
        {{ formatDate(date) }}
      </button>
      <!-- Help Button -->
      <button class="action-button" :class="{ 'button-pressed': showHelp }" @click="toggleHelp">?</button>
    </div>
<!-- Info Section -->
<div v-if="showHelp" class="info-section">
  <p v-if="isLoading">Predicting games for you please wait...</p>
  <p v-else-if="selectedDates.length > 0">
    You are displaying games of {{ formattedSelectedDates }}<br>
    <span v-if="sortMode === 'featured'">Sorted by featured (popularity)</span>
    <span v-else-if="sortMode === 'safe'">Sorted by probability (safest in terms of outcome to happen)</span>
    <span v-else>You can sort games by using Featured & Safe buttons.</span>
  </p>
  <p v-else>You do not have any dates selected, please select the dates of games you want to display</p>
</div>
    <div class="games-list">
      <GameTile
        v-for="game in filteredGames"
        :key="game.name"
        :game="game"
        :isSelected="selectedGame === game"
        @game-selected="gameSelected"
      />
    </div>
  </div>
</template>


<script>
import { format, parseISO, isToday, isTomorrow, isYesterday } from 'date-fns';
import GameTile from './GameTile.vue';
import Fuse from 'fuse.js';

export default {
  components: {
    GameTile
  },
  props: {
    sport: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      games: [],
      uniqueDates: [],
      selectedDates: [],
      filteredGames: [],
      selectedGame: null,
      searchTerm: '',
      sortMode: null,
      showHelp: true,
      isLoading: true,
      fuse: null,
    };
  },
  computed: {
    formattedSelectedDates() {
      return this.selectedDates.map(date => this.formatDate(date)).join(' & ');
    },
  },
  mounted() {
    this.fetchGamesData();
  },
  methods: {
    toggleHelp() {
      this.showHelp = !this.showHelp;
    },
    async fetchGamesData() {
      try {
        console.log("Fetching game data...");
        const apiUrl = `${process.env.VUE_APP_API_URL}/${this.sport}/fetchAllGamesShort`; // Dynamic endpoint
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP status ${response.status}`);
        }
        this.games = await response.json();
        console.log("Data fetched successfully:", this.games);
        this.initializeGames();
      } catch (error) {
        console.error('Failed to fetch games data:', error);
      } finally {
        this.isLoading = false;  // Set loading to false after fetching is done
      }
    },
    initializeGames() {
      this.games = this.games.map(game => ({
        name: `${game.first_team} vs ${game.second_team}`,
        league: game.league,
        date: game.date,
        id: game.id,
        value: game.value,
        pro_category: game.pro_category,
        pro_prob: game.pro_prob,
        pro_odds: game.pro_odds
      }));
      this.uniqueDates = [...new Set(this.games.map(game => game.date))].sort();
      
      // Filter uniqueDates to include only relevant dates
      this.selectedDates = this.uniqueDates.filter(date => isToday(parseISO(date)));

      this.setupFuse();

      this.filterGames();
    },
    setupFuse() {
      const options = {
        includeScore: true,
        keys: ['name', 'league']
      };
      this.fuse = new Fuse(this.games, options);
    },
    filterGames() {
  let games = this.games;

  // Filter games based on selected dates
  if (this.selectedDates.length > 0) {
    games = games.filter(game => this.selectedDates.includes(game.date));
  }

  // Apply search term filter if any
  if (this.searchTerm) {
    const results = this.fuse.search(this.searchTerm);
    games = results.map(result => result.item);
  }

  this.filteredGames = games;
  this.applySort();
},
    applySort() {
      if (this.sortMode === 'featured') {
        this.filteredGames.sort((a, b) => b.value - a.value || new Date(a.date) - new Date(b.date));
      } else if (this.sortMode === 'safe') {
        this.filteredGames.sort((a, b) => b.pro_prob - a.pro_prob);
      }
    },
    formatDate(date) {
      const parsedDate = parseISO(date);
      if (isToday(parsedDate)) {
        return 'Today';
      } else if (isTomorrow(parsedDate)) {
        return 'Tomorrow';
      } else if (isYesterday(parsedDate)) {
        return 'Yesterday';
      }
      return format(parsedDate, 'EEEE');
    },
    toggleDateFilter(date) {
      const index = this.selectedDates.indexOf(date);
      if (index > -1) {
        this.selectedDates.splice(index, 1);
      } else {
        this.selectedDates.push(date);
      }
      this.filterGames();
    },
    toggleSortMode(mode) {
      this.sortMode = this.sortMode === mode ? null : mode;
      this.filterGames();
    },
    gameSelected(game) {
      this.selectedGame = game;
      console.log("Game selected with ID:", game.name);
      this.$emit('game-selected', game.id);
    }
  }
}
</script>


<style scoped>
.game-search input {
  margin-top: 20px;
  width: 98%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Helvetica Neue', sans-serif;
}

.flexible-buttons-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 5px 0;
}

.action-button {
  margin: 5px;
  padding: 5px 10px;
  font-size: 0.75rem;
  font-family: 'Helvetica Neue', sans-serif;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.3s, background-color 0.3s;
}

.action-button:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.button-pressed {
  background-color: rgba(219, 232, 238, 0.7);
  color: rgb(68, 68, 68);
  border-color: #4b8aa7;
}

.games-list {
  margin-top: 20px;
  overflow-y: auto;
}

@media (max-width: 600px) {
  .game-search input, .flexible-buttons-container, .games-list {
    margin: 3px;
  }

  .flexible-buttons-container {
    flex-direction: column;
  }

  .action-button {
    margin: 3px;
    padding: 3px 7px;
    width: 100%; /* Makes buttons full width */
  }
  .info-section p {
    font-size: 0.6rem; /* Reduce the font size on mobile */
  }
}
</style>
