<template>
  <div v-if="gameDetails" class="game-details">
    <GameInfo :gameDetails="gameDetails" />
    <br>
    <PredictionPreviews :gameDetails="gameDetails" :sport="sport" />
    <br>
    <TeamStatistics :gameDetails="gameDetails" /> <!-- New component here -->
    <br>
    <GamePredictions :gameDetails="gameDetails" :sport="sport" />
    <br>
    <EditorComments :gameId="selectedGameId" :comments="comments" /> <!-- Pass comments as prop -->
  </div>
  <div v-else class="no-games">
    <WelcomeText/>
    <p>No game selected or game details are not available.</p>
  </div>
</template>


<script>
import GameInfo from './GameInfo.vue';
import GamePredictions from './GamePredictions.vue';
import EditorComments from './EditorComments.vue';
import WelcomeText from './WelcomeText.vue';
import PredictionPreviews from './PredictionPreviews.vue';
import TeamStatistics from './TeamStatistics.vue';

export default {
  components: {
    GameInfo,
    GamePredictions,
    EditorComments,
    WelcomeText,
    PredictionPreviews,
    TeamStatistics
  },
  props: {
    selectedGameId: {
      type: [String, Number],
      default: null
    },
    sport: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      gameDetails: null,
      comments: []  // Added to hold comments for the selected game
    };
  },
  watch: {
    selectedGameId: {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.fetchGameDetails(newId);
          this.fetchComments(newId);  // Fetch comments when game ID changes
        } else {
          this.gameDetails = null;
          this.comments = [];  // Clear comments when no game is selected
        }
      }
    }
  },
  methods: {
    async fetchGameDetails(gameId) {
      try {
        console.log(`Fetching game details for ${this.sport}...`);
        const apiUrl = `${process.env.VUE_APP_API_URL}/${this.sport}/fetchGameDetails/${gameId}`;
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP status ${response.status}`);
        }
        this.gameDetails = await response.json();
        console.log(`Game details fetched successfully for ${this.sport}:`, this.gameDetails);
      } catch (error) {
        console.error(`Failed to fetch game details for ${this.sport}:`, error);
      }
    },
    async fetchComments(gameId) {
      try {
        const apiUrl = `${process.env.VUE_APP_API_URL}/fetchGameComment/${gameId}`;
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch comments, status: ${response.status}`);
        }
        this.comments = await response.json();
      } catch (error) {
        console.error('Error fetching comments:', error.message);
        this.comments = [];  // Clear comments on error
      }
    }
  }
};
</script>

<style scoped>
.game-details {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
  background-color: #ffffff;
}
.no-games {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%; /* Take full height of the viewport */
}

</style>
