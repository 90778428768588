<template>
  <div class="fut-game-search">
    <AppHeader/>
    <ResultPage :gameId="gameId" :sport="sportType" />
    <AppFooter/>
  </div>
</template>

<script>
import ResultPage from './ResultPage.vue';
import { useRouter } from 'vue-router';
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  components: {
    ResultPage,
    AppHeader,
    AppFooter
  },
  props: {
    gameId: String, // Accept gameId from the router as a prop
    sportType: {
      type: String,
      required: true,
      default: 'Football' // Default sport type if none provided
    }
  },
  setup() {
    const router = useRouter();

    const navigateHome = () => {
      router.push('/');
    };

    const openPortfolio = () => {
      window.open('https://erdemkaratash.github.io/portfolio', '_blank');
    };

    return {
      navigateHome,
      openPortfolio
    };
  }
}
</script>

<style scoped>
.fut-game-search {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures minimum height of the entire viewport */
}
</style>
