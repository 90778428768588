<template>
  <div class="header">
    <div class="game-details">
      <h3>{{ gameDetails.league }}</h3>
      <h4>{{ formattedDate }}</h4>
    </div>
    <br>
    <div class="team-info">
      <div class="team-logo-container">
        <div class="team-logo" :style="getLogoStyle(gameDetails.first_team_logo)"></div>
        <p class="team-name">{{ gameDetails.first_team }}</p>
      </div>
      <div class="vs">vs</div>
      <div class="team-logo-container">
        <div class="team-logo" :style="getLogoStyle(gameDetails.second_team_logo)"></div>
        <p class="team-name">{{ gameDetails.second_team }}</p>
      </div>
    </div>
  </div>
</template>

  
  <script>
  import { format, parseISO } from 'date-fns';
  
  export default {
    props: {
      gameDetails: {
        type: Object,
        required: true
      }
    },
    computed: {
      formattedDate() {
        return this.gameDetails ? format(parseISO(this.gameDetails.date), 'eeee, MMMM do, yyyy') : '';
      }
    },
    methods: {
      getLogoStyle(logoUrl) {
        return {
          backgroundImage: `url(${logoUrl || '/path-to-placeholder-image.png'})`
        };
      }
    }
  };
  </script>
  
  <style scoped>
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .game-details {
    text-align: center;
  }
  
  .team-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .team-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:1;  /* Ensures even distribution of space */
  }
  
  .team-logo {
    width: 14vw;
    height:12vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
  }
  
  .team-name {
    font-size:medium;
    font-weight: bold;
    margin-top: 8px;
    white-space: normal;
    overflow-wrap: break-word;
    text-align: center;
    color: #495057;
  }
  
  .vs {
    font-size: medium;
    font-weight: bold;
    margin: 0 20px;
    align-self: center;
    color: #495057;
  }
  
  h3, h4 {
    margin: 5px 0;
    color: #495057;
  }
  </style>
  